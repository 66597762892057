<template>
  <modal
    @submit="submit()"
    @close="$emit('close')"
    :label="title"
    blocking
    show_cancel
    large
  >
    <template v-slot:content>
      <textarea
        class="rounded border border-steel-gray resize-none w-full h-96"
        v-model="noteText"
        ref="mentor_note_input"
      />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/modal/Modal";
import { mapGetters } from "vuex";
export default {
  name: "NoteModal",
  components: { Modal },
  emits: ["close", "submit"],
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs.mentor_note_input.focus();
  },
  data() {
    return {
      noteText: this.note.text,
    };
  },
  methods: {
    submit() {
      let note = { ...this.note };
      note.text = this.noteText;
      note.author_id = this.getUser.person_id;
      this.$emit("submit", note);
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    isNew() {
      return this.note.note_id !== null;
    },
    title() {
      return `${this.isNew ? "Edit" : "Add New"} Note`;
    },
  },
};
</script>

<style scoped></style>
