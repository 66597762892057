<template>
  <note-modal
    v-if="editingNote"
    @close="closeModal()"
    @submit="closeModal"
    :note="noteEditing"
  />
  <loading-spinner v-if="loading" />
  <dynamic-table
    v-else
    :records="sortNotesByDate"
    :fields="fields"
    :pagination-page="page"
    :pagination-page-size="pageSize"
    :pagination-total-items="totalNotes"
    striped
    hover
    pagination
    @pageChanged="nextPage($event)"
    :no-data-msg="`The ${
      isStudent ? 'student' : 'mentor'
    } has no notes associated with them`"
  >
    <template #cell(author)="{ item }">
      <div class="text-center">
        {{ authorDetails(item) }}
        <!--        {{-->
        <!--          item.author.firstname-->
        <!--        }}-->
        <!--        {{ item.author.lastname }}-->
      </div>
    </template>
    <template #cell(added)="{ item }">
      <div class="text-center">
        {{ editDate(item.added) }}
      </div>
    </template>
    <template #cell(updated)="{ item }">
      <div class="text-center">
        {{ editDate(item.updated) }}
      </div>
    </template>
    <template #cell(edits)="{ item }">
      <div class="flex justify-center items-center">
        <button
          aria-label="edit-note-on-mentor-button"
          @click="editNote(item)"
          title="Edit Note"
          class="w-6 h-6 mr-3 text-dark-purple hover:bg-dark-purple hover:text-white border rounded flex justify-center items-center disabled:bg-light-gray disabled:text-slate-gray"
        >
          <font-awesome-icon icon="pen-fancy" />
        </button>
        <button
          aria-label="delete-note-on-mentor-button"
          @click="deleteNote(item)"
          class="w-6 h-6 text-dark-purple hover:bg-dark-purple hover:text-white border rounded flex justify-center items-center disabled:bg-light-gray disabled:text-slate-gray"
        >
          <font-awesome-icon icon="trash" />
        </button>
      </div>
    </template>
  </dynamic-table>
</template>

<script>
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get, put, post, del } from "@/composables/httpUtil";
import NoteModal from "@/components/forms/NoteModal";
import { FormatDate } from "@/composables/date_utils";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "AddEditNotes.vue",
  components: { LoadingSpinner, NoteModal, DynamicTable },
  props: {
    mentorId: {
      type: String,
    },
    isStudent: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.loading = true;
    this.getNotes();
  },
  data() {
    return {
      notes: [],
      fields: [
        {
          name: "text",
          display: "Note",
          class: "w-1/2 text-left overflow-hidden",
        },
        { name: "author", class: "w-1/8 overflow-hidden" },
        { name: "added", display: "Created", class: "w-1/8 overflow-hidden" },
        { name: "updated", display: "Edited", class: "w-1/8 overflow-hidden" },
        {
          name: "edits",
          display: " ",
          class: "w-1/8 overflow-hidden",
        },
      ],
      loading: false,
      page: 1,
      totalPages: 0,
      pageSize: 25,
      totalNotes: 0,
      editingNote: false,
      noteEditing: null,
      paginationPage: 1,
    };
  },
  methods: {
    get,
    put,
    post,
    del,
    FormatDate,
    getNotes() {
      this.get(this.baseUrl)
        .then((notes) => {
          this.notes = notes.data;
          this.page = notes.page_number;
          this.totalPages = notes.total_pages;
          this.pageSize = notes.page_size;
          this.totalNotes = notes.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editDate(edit_date) {
      return this.FormatDate(edit_date);
    },
    editNote(note = false) {
      if (!note) {
        this.noteEditing = { note_id: null, text: null };
      } else {
        this.noteEditing = { ...note };
      }
      this.editingNote = true;
    },
    closeModal(note) {
      if (note) {
        (note.note_id !== null
          ? this.put(`${this.writeUrl}/${note.note_id}`, note)
          : this.post(this.writeUrl, note)
        ).then((sub_note) => {
          const note_idx = this.notes.findIndex(
            (n) => n.note_id === sub_note.note_id
          );
          if (note_idx >= 0) {
            this.notes[note_idx] = sub_note;
          } else {
            this.notes.push(sub_note);
          }
          this.hideNoteModal();
        });
      } else {
        this.hideNoteModal();
      }
    },
    deleteNote(note) {
      del(`${this.writeUrl}/${note.note_id}`).then((sub_note) => {
        const note_idx = this.notes.findIndex(
          (n) => n.note_id === sub_note.note_id
        );
        this.notes.splice(note_idx, 1);
      });
    },
    hideNoteModal() {
      this.noteEditing = null;
      this.editingNote = false;
    },
    authorDetails: function (note) {
      if ("author" in note && note["author"] !== null) {
        if (note.author.lastname.toLowerCase() === "temp") {
          return "Admin";
        } else {
          return `${
            note.author.nickname ? note.author.nickname : note.author.firstname
          } ${note.author.lastname}`;
        }
      } else return "Admin";
    },
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.getNotes();
    },
  },
  computed: {
    ...mapGetters(["userId"]),
    baseUrl: function () {
      return `admin/${this.isStudent ? "students" : "mentors"}/${
        this.mentorId
      }/note?page=${this.paginationPage}`;
    },
    writeUrl: function () {
      return `admin/${this.isStudent ? "students" : "mentors"}/${
        this.mentorId
      }/note`;
    },
    sortNotesByDate: function () {
      let output = [...this.notes];
      return output.sort((a, b) => {
        return new Date(a.updated) - new Date(b.updated);
      });
    },
  },
};
</script>

<style scoped></style>
